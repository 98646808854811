<template>
  <error-template
    img-url="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/error-pages/404.svg"
    :title="$t('pages.error.404.title')"
    :sub-title="$t('pages.error.404.subtitle')"
  >
    <el-button type="primary" @click="goHome">
      {{ $t('pages.error.404.button') }}
    </el-button>
  </error-template>
</template>

<script>
import ErrorTemplate from './components/error-template'
export default {
  name: 'Page404',
  components: { ErrorTemplate },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped></style>
